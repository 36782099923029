// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/category`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/category/export`
// 保存巡更内容
const saveCategoryUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/category`
// 获取巡更内容详情
const getCategoryUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/category/`

export {
  getListURL,
  exportListURL,
  saveCategoryUrl,
  getCategoryUrl
}
