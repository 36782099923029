import { render, staticRenderFns } from "./form.vue?vue&type=template&id=5118c9c8&scoped=true"
import script from "./form.vue?vue&type=script&lang=js"
export * from "./form.vue?vue&type=script&lang=js"
import style0 from "./form.vue?vue&type=style&index=0&id=5118c9c8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5118c9c8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\teamHonorWork\\tianheyun\\managerforshushunew\\node_modules\\_vue-hot-reload-api@2.3.4@vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5118c9c8')) {
      api.createRecord('5118c9c8', component.options)
    } else {
      api.reload('5118c9c8', component.options)
    }
    module.hot.accept("./form.vue?vue&type=template&id=5118c9c8&scoped=true", function () {
      api.rerender('5118c9c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/patrolMgt/patrolCategory/form.vue"
export default component.exports