<template>
  <div class="patrolCategory-container">
    <form-panel ref="formPanel" :form="form" v-bind='submitConfig' :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore">
      <col2-detail>
        <col2-block title="巡更点分类信息">
          <template v-if="!isConmunityUser">
            <el-form-item prop="regionId" v-if="!$route.query.id" label="所属公司" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
              <v-select2 :width="184" v-model="form.regionId" placeholder="所属公司" v-bind="regionIdParam" @onChange="onRegionIdChange" />
            </el-form-item>
            <el-form-item v-else  label="所属公司">
              <span>{{ regionName }}</span>
            </el-form-item>
          </template>
          <el-form-item label="分类级别">
            <v-select v-model="form.level" :options="categoryLevelOps" @change="levelChange"></v-select>
          </el-form-item>
          <el-form-item label="上级分类" v-show="form.level === 2" :rules="[{ required: form.level===2, message: '请选择上级分类', trigger: 'change' }]" prop="parentId">
            <v-select v-model="form.parentId" :options="firstOps"></v-select>
          </el-form-item>
          <el-form-item label="分类名称" :rules="[{ required:true, message: '请输入分类名称', trigger: 'blur' }]" prop="name">
            <v-input v-model="form.name" placeholder="请输入分类名称" class='contengLine' :width="200"></v-input>
          </el-form-item>
          <el-form-item label="描述">
            <v-textarea placeholder="请输入备注信息" v-model="form.remark" type="textarea" :maxlength="150"></v-textarea>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getCategoryUrl, saveCategoryUrl } from './api'
import { regionParams } from 'common/select2Params'
import { categoryLevelOps } from './map'
import { getFirstOpts } from './../map'
import { Col2Block, Col2Detail } from 'components/bussiness'
export default {
  name: 'patrolCategoryForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      submitUrl: saveCategoryUrl,
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: this.$route.query.id ? 'put' : 'post'
      },
      regionIdParam: regionParams,
      form: {
        id: 0,
        name: '',
        level: 1,
        remark: '',
        parentId: null
      },
      categoryLevelOps: categoryLevelOps,
      name: '',
      regionName: '',
      firstOps: []
    }
  },
  computed: {
    isConmunityUser () {
      const userType = String(this.$store.state.userInfo.userType)
      return userType === '101' || userType === '102'
    }
  },
  async created () {
    this.firstOps = await getFirstOpts()
  },
  mounted () {
    if (this.$route.query.id) {
      this.getDetailData(this.$route.query.id)
    }
  },
  methods: {
    // 分类级别切换
    levelChange (value) {
      if (value === 2) {
        if (this.$route.query.id) {
          this.form.parentId = this.firstOps[0].value
        }
      }
    },
    // 获取详情数据
    getDetailData (id) {
      let _this_ = this
      this.$axios.get(getCategoryUrl + id).then(res => {
        if (res.status === 100) {
          let rdata = res.data
          if (rdata) {
            _this_.regionName = rdata.regionName
            _this_.form = Object.assign(_this_.form, rdata)
            console.log(_this_.form)
          }
        }
      })
    },
    // 保存前数据处理
    submitBefore (data) {
      if (data.level === 1) {
        data.parentId = 0
      }
      return true
    },
    async onRegionIdChange () {
      this.firstOps = await getFirstOpts({ regionId: this.form.regionId })
      this.form.parentId = null
    }
  }
}
</script>

<style lang="scss" scoped>
.patrolCategory-container {
  .cell-title {
    text-align: left;
    padding-left: 10px;
    margin: 15px 0;
    display: flex;
    align-items: center;
    .pre-point {
      // display: inline-block;
      border-radius: 50%;
      background-color: #dce2e7;
      height: 8px;
      width: 8px;
      margin-right: 5px;
    }
  }
  .contentList {
    .contengLine {
      margin-right: 10px;
    }
    .addBtn {
      margin-left: 140px;
    }
  }
}
</style>
