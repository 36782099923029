var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patrolCategory-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "巡更点分类信息" } },
                [
                  !_vm.isConmunityUser
                    ? [
                        !_vm.$route.query.id
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "regionId",
                                  label: "所属公司",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择所属公司",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: {
                                        width: 184,
                                        placeholder: "所属公司",
                                      },
                                      on: { onChange: _vm.onRegionIdChange },
                                      model: {
                                        value: _vm.form.regionId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "regionId", $$v)
                                        },
                                        expression: "form.regionId",
                                      },
                                    },
                                    "v-select2",
                                    _vm.regionIdParam,
                                    false
                                  )
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: "所属公司" } },
                              [_c("span", [_vm._v(_vm._s(_vm.regionName))])]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类级别" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.categoryLevelOps },
                        on: { change: _vm.levelChange },
                        model: {
                          value: _vm.form.level,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "level", $$v)
                          },
                          expression: "form.level",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.level === 2,
                          expression: "form.level === 2",
                        },
                      ],
                      attrs: {
                        label: "上级分类",
                        rules: [
                          {
                            required: _vm.form.level === 2,
                            message: "请选择上级分类",
                            trigger: "change",
                          },
                        ],
                        prop: "parentId",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.firstOps },
                        model: {
                          value: _vm.form.parentId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parentId", $$v)
                          },
                          expression: "form.parentId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入分类名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "name",
                      },
                    },
                    [
                      _c("v-input", {
                        staticClass: "contengLine",
                        attrs: { placeholder: "请输入分类名称", width: 200 },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请输入备注信息",
                          type: "textarea",
                          maxlength: 150,
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }