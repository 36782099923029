// import { generateMapByOpts } from 'common/utils'
const categoryLevelOps = [{
  text: '一级',
  value: 1
}, {
  text: '二级',
  value: 2
}]

export {
  categoryLevelOps
}
